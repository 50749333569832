import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { Collapse, makeStyles } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { HexColorPicker } from "react-colorful";

import SEO from "../../components/SEO";
import Link from "../../components/v2/Link";
import LazyImage from "../../components/v4/LazyLoadImage/LazyImage";
import FaqSection from "../../components/v4/FaqSection/FaqSection";
import TruestedSection from "../../components/v4/TrustedSection/TruestedSection";
import TestimonialSlider from "../../components/v3/TestimonialSlider/TestimonialSlider";

import whiteCheckCircle from "../../images/v4/whatsappPage/icons/whiteCheckCircle.svg";
import greenCheckCircle from "../../images/v4/whatsappPage/icons/greenCheckCircle.svg";
import linkGeneartionBackground from "../../images/v4/chatButtnGenerator/images/linkGeneartionBackground.png";
import partnerIcon1 from "../../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../../images/v4/aiPage/icons/partnerIcon8.png";
import experienceImg1 from "../../images/v4/linkGeneration/images/experienceImg1.png";
import downArrow from "../../images/v4/aiPage/icons/downArrow.svg";
import whatsappBusinessPartner from "../../images/v3/singaporeCrmHomepage/whatsappBusinessPartner.webp";
import metaBusinessPartner from "../../images/v3/singaporeCrmHomepage/metaBusinessPartner.webp";
import whatsAppFeatureIcon from "../../images/v4/homepage/icons/whatsAppFearureIcon.svg";

import heroSectionBackground from "../../images/v4/chatButtnGenerator/images/heroSectionBackground.png";
import heroSectionImage from "../../images/v4/chatButtnGenerator/images/heroSectionImage.png";
import experienceContainerBackground from "../../images/v4/linkGeneration/images/experienceContainerBackground.png";
import transformBackgroundImage from "../../images/v4/linkGeneration/images/transformBackgroundImage.png";
import partnerSectionBackground from "../../images/v4/linkGeneration/images/partnerSectionBackground.png";
import quickGuideImage from "../../images/v4/chatButtnGenerator/images/quickGuideImage.png";
import instantChatBg1 from "../../images/v4/chatButtnGenerator/images/instantChatBg1.png";
import instantChatBg2 from "../../images/v4/chatButtnGenerator/images/instantChatBg2.png";
import instantChatBg3 from "../../images/v4/chatButtnGenerator/images/instantChatBg3.png";
import instantChatIcon1 from "../../images/v4/chatButtnGenerator/icons/instantChatIcon1.svg";
import instantChatIcon2 from "../../images/v4/chatButtnGenerator/icons/instantChatIcon2.svg";
import instantChatIcon3 from "../../images/v4/chatButtnGenerator/icons/instantChatIcon3.svg";
import wpChatIcon from "../../images/v4/chatButtnGenerator/icons/wpChatIcon.svg";
import copyIcon from "../../images/v4/linkGeneration/icons/copyIcon.svg";
import growingIcon1 from "../../images/v4/linkGeneration/icons/growingIcon1.svg";
import growingIcon2 from "../../images/v4/linkGeneration/icons/growingIcon2.svg";
import growingIcon3 from "../../images/v4/linkGeneration/icons/growingIcon3.svg";
import growingIcon4 from "../../images/v4/linkGeneration/icons/growingIcon4.svg";
import growingIcon5 from "../../images/v4/linkGeneration/icons/growingIcon5.svg";
import benifitImage from "../../images/v4/chatButtnGenerator/images/benifitImage.png";
import benefitBgImage from "../../images/v4/chatButtnGenerator/images/benefitBgImage.png";
import qs from "qs";
import { Value } from "sass";

const useStyles = makeStyles((theme) => ({
  whatsappSection: {
    backgroundImage: `url(${heroSectionBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: "50px",
    "& $aiSectionLeft": {
      width: "50%",
      gap: "25px",
    },

    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
      marginTop: "70px",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
      paddingTop: "0px",
      "& .centerContainer": {
        width: "100%",
        marginTop: "30px",
      },
      "& .buttonContaienr": {
        "& .primaryButton": {
          width: "100%",
        },
      },
    },
  },
  aiSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  aiSectionLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "& .highlighterText": {
      background: "linear-gradient(90deg, #7AFFAB 0%, #80C8FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  aiSectionRight: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  partnerSlide: {
    width: "185px !important",
    "& img": {
      width: "185px",
      filter: "brightness(0.1)",
    },
    "@media only screen and (max-width: 600px)": {
      width: "140px !important",
      marginRight: "30px !important",
      "& img": {
        width: "140px",
        filter: "brightness(0.1)",
      },
    },
  },
  partnerSlider: {
    padding: "10px 0 80px !important",
    "@media only screen and (max-width: 600px)": {
      paddingBottom: "40px !important",
    },
  },

  listContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    color: "#A2BED3",
  },
  integrationHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "30px",
    },
  },
  integrationLeft: {
    width: "42%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benefitSection: {
    background: "#F6EBFF",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& $integrationLeft": {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "10px",
    },
    "& $integrationHeader": {
      justifyContent: "space-around",
      alignItems: "center",
      flexDirection: "row-reverse",
    },
    "@media only screen and (max-width: 600px)": {
      "& $integrationLeft": {
        width: "100%",
      },
      "& $integrationRight": {
        width: "100%",
      },
    },
  },
  pills: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    marginTop: "25px",
  },
  pill: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 16px",
    borderRadius: "30px",
    border: "1px solid #02AE7F",
    gap: "10px",
    background: "#fff",
  },
  moreFeatures: {
    "& .header": {
      display: "flex",
      width: "50%",
      gap: "10px",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
    },
    "& .body": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",
      width: "100%",
      marginTop: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      "& .body": {
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: "15px",
      },
    },
    "@media only screen and (min-width: 1900px)": {
      "& .container": {
        paddingTop: "20px",
      },
    },
  },
  featureContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "32%",
    gap: "20px",
    // boxShadow: "0px 0px 16px #C1D7DB66",
    borderRadius: "16px",
    background: "#fff",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    "& p": {
      width: "90%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& p": {
        width: "100%",
      },
    },
  },
  experienceContainer: {
    paddingTop: "300px",
    backgroundImage: `url(${experienceContainerBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& .centerContainer": {
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "500px",
    },
    "@media only screen and (min-width: 1900px)": {
      paddingTop: "400px",
    },
  },
  experienceInnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  experienceContainerLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
  body: {
    textAlign: "left",
    margin: "10px 0 0 ",
    "& img": {
      display: "none",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        display: "block",
        width: "100%",
        marginTop: "15px",
      },
    },
  },
  leftNavigation: {
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "150px",
      },
    },
  },
  experienceContainerRight: {
    width: "40%",
    minHeight: "450px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      // display: "none",
      width: "100%",
      minHeight: "auto",
    },
  },
  faqBox: {
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "5.5s ease",
      width: "100%",
    },
  },
  faqHeader: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  experienceContainerHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "& h2": {
      width: "50%",
    },
    "& p": {
      width: "50%",
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
      "& h2": {
        width: "100%",
      },
      "& p": {
        width: "100%",
      },
    },
  },
  supportSystem: {
    backgroundImage: `url(${linkGeneartionBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: "350px",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      width: "60%",
      margin: "0 auto",
      textAlign: "center",
      "& p": {
        width: "80%",
      },
      "& img": {
        width: "100%",
        maxWidth: "56px",
        position: "relative",
        top: "10px",
      },
      "& h2": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      borderRadius: "0",
      "& .centerContainer": {
        width: "100%",
        "& img": {
          maxWidth: "25px !important",
        },
      },
    },
    "@media only screen and (max-width: 1200px)": {
      "& .centerContainer": {
        "& img": {
          maxWidth: "40px !important",
        },
      },
    },
  },
  broadcastLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "60%",
    gap: "32px",
    textAlign: "left",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  broadcastRightContainer: {
    width: "40%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  broadcastImg: {
    "& img": {
      width: "100%",
      position: "relative",
      bottom: "0",
    },
  },
  supportBox: {
    top: "100%",
    marginTop: "60px",
    width: "100%",
    display: "flex",
    padding: "40px",
    position: "absolute",
    backgroundImage: `url(${benefitBgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    borderRadius: "20px",
    backdropFilter: "blur(30px)",
    justifyContent: "space-between",
    "& $broadcastLeftContainer": {
      width: "45%",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      width: "94%",
      padding: "15px",
      marginTop: "20px",
      gap: "20px",
    },
  },
  pointers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  cardPointer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    "@media only screen and (max-width: 600px)": {
      alignItems: "center",
    },
  },
  commonPageFooterSection: {
    background: "linear-gradient(89deg, #015352 0%, #00926A 100%)",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  centerContainer: {
    marginTop: "80px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 600px)": {
      marginTop: "30px",
    },
  },
  rewards: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    gap: "20px",
    borderRadius: "24px",
    width: "50%",
    padding: "10px 80px",
    background: "#fff",
    "& .border": {
      borderRight: "1px solid #DBDBDB",
      width: "0",
    },
    "& img": {
      width: "60%",
      "@media only screen and (max-width: 600px)": {
        width: "45%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "20px",
    },
  },
  growingBusiness: {
    background: "#2F0056",
    paddingBottom: "320px",
    position: "relative",
    "& .header": {
      width: "55%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      gap: "32px",
    },
    "& $featureContainer": {
      gap: "20px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      paddingBottom: "220px",
    },
  },
  broadcastContainerInner: {
    gap: "30px",
    width: "100%",
    display: "flex",
    padding: "0 8rem 0rem 8rem",
    // padding: "0 0rem .5rem 0rem",
    alignItems: "stretch",
    justifyContent: "flex-start",
    "& swiper-wrapper": {
      alignItems: "stretch !important",
    },
    // cursor: "grab",
    // "&::-webkit-scrollbar-track": {
    //   "-webkit-box-shadow": "none",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar": {
    //   width: "5px",
    //   height: "5px",
    //   borderRadius: "50%",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#D2DBE4",
    //   borderRadius: "50px",
    // },
    "& $featureContainer": {
      width: "31%",
      height: "auto  !important",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px !important",
      paddingBottom: "10px !important",
    },
    "@media only screen and (min-width: 1900px)": {
      paddingLeft: "23rem !important",
      paddingRight: "23rem !important",
    },
  },
  absoulteCard: {
    position: "absolute",
    margin: "0 auto",
    width: "100%",
    top: "75%",
  },
  stripSection: {
    paddingTop: "280px",
    backgroundImage: `url(${partnerSectionBackground})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "& .centerContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "180px",
      "& .centerContainer": {
        textAlign: "center",
      },
    },
  },
  formContainer: {
    padding: "40px 32px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "50%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "45px",
    "@media only screen and (max-width: 600px)": {
      padding: "20px",
      gap: "30px",
      width: "100%",
    },
  },
  formHeader: {},
  formBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "30px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "25px",
    },
  },
  inputBoxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "30px",
    position: "relative",
    "&::before": {
      left: "20px",
      width: "0",
      height: "100%",
      content: '""',
      position: "absolute",
      borderRight: "2px dashed #cccccc",
      top: "40px",
      "@media only screen and (max-width: 600px)": {
        left: "15px",
        top: "30px",
      },
    },
    "&:nth-child(5):before": {
      display: "none",
    },
    "&:nth-child(6):before": {
      display: "none",
    },
    "&:last-child:before": {
      display: "none",
    },
    "&:last-child": {
      "& $formStep": {
        background: "transparent",
      },
    },
    "&:nth-child(6)": {
      "& $formStep": {
        background: "transparent",
      },
    },
    "@media only screen and (max-width: 600px)": {
      gap: "15px",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "15px",
    width: "calc(100% - 70px)",
    "& .primaryButton": {
      width: "100%",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      width: "calc(100% - 45px)",
      gap: "10px",
    },
  },
  inputContainerHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
  },
  inputContainerBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",
  },
  input: {
    width: "100%",
    border: "1px solid #D9D9D9",
    outline: "none",
    padding: "15px 10px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    WebkitAppearance: "none",
    MozAppearance: "textfield",
    margin: "0",
    justifyContent: "space-between",
    "&::placeholder": {
      color: "#8A8A8A",
    },

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#EDF1F6",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D2DBE4",
      borderRadius: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      margin: 0,
    },
  },
  formStep: {
    height: "40px",
    minWidth: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#02AE7F",
    borderRadius: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "30px",
      height: "30px",
      minWidth: "30px",
    },
  },
  formFooter: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "57%",
    "& .primaryButton": {
      width: "71%",
      marginLeft: "70px",
    },
  },
  whatsappButton: {
    border: "2px solid #02AE7F",
    background: "transparent",
    padding: "15px 24px",
    borderRadius: "100px",
    cursor: "pointer",
    outline: "none",
    textDecoration: "none",
    color: "#007253",
    display: "flex",
    lineHeight: "1.75 !important",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s ease",
    width: "80%",
    fontWeight: "600",
    zIndex: "0",
    gap: "10px",
    textDecoration: "none !important",
    "&:hover": {
      color: "#007253",
    },
    "& img": {
      width: "28px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  formBoxContainerBox: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    borderRadius: "25px",
    overflow: "hidden",
    boxShadow: "0px 0px 32px #6DE7C56E",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  qrContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "50%",
    height: "auto",
    padding: "40px 0",
    background: "linear-gradient(180deg, #CEFFEA 0%, #CBE8FF 100%)",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "20px",
    },
  },
  qrContainerInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    gap: "25px",
    pointerEvents: "none",
    textAlign: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  qrCodeContainer: {
    border: "2px solid #B4DAEB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "10px",
    "& img": {
      height: "196px",
      width: "196px",
      borderRadius: "16px",
    },
  },
  downloadQrButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    color: "#007188",
    textDecoration: "underline",
    "&:hover": {
      color: "#007188",
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "35px",
    "& input": {
      padding: 0,
      height: "0",
      width: "0",
      cursor: "pointer",
      position: "relative",
    },
    "& label": {
      position: "relative",
      cursor: "pointer",
    },
    "& input::before": {
      content: "''",
      backgroundColor: "#fff",
      border: "1px solid #0291AE",
      height: "20px",
      width: "20px",
      borderRadius: "5px",
      background: "#fff",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
      cursor: "pointer",
      marginRight: "5px",
    },
    "& input:checked::after": {
      top: "3px",
      left: "7px",
      width: "5px",
      border: "1px solid #fff",
      height: "12px",
      content: "''",
      position: "absolute",
      transform: "rotate(45deg)",
      borderWidth: "0 2px 2px 0",
    },
    "& input:checked::before": {
      background: "#0291AE",
    },
    "@media only screen and (max-width: 600px)": {
      gap: "25px",
      width: "100%",
    },
    "& a": {
      color: "#208EEC",
      "&:hover": {
        color: "#208EEC",
      },
    },
  },
  checkboxContainerOuter: {
    width: "100%",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  stepBox: {
    width: "100%",
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "5.5s ease",
      width: "100%",
    },
  },
  stepBoxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
    cursor: "pointer",
  },
  stepBoxBody: {
    padding: "10px 0",
    width: "100%",
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "25px",
      width: "100%",
    },
  },
  optionInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
  },
  optionInput: {
    width: "100%",
    border: "1px solid #D9D9D9",
    margin: "0",
    display: "flex",
    outline: "none",
    padding: "15px 10px",
    alignItems: "center",
    borderRadius: "8px",
    MozAppearance: "textfield",
    justifyContent: "space-between",
    WebkitAppearance: "none",
    resize: "none",
  },
  flexOptions: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "30px",
    width: "100%",
    "& $optionInputContainer": {
      width: "50%",
    },
  },
  colorInput: {
    display: "flex",
    gap: "10px",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  color: {
    height: "100%",
    width: "100%",
    padding: "5px",
    borderRadius: "8px",
  },
  radioGroup: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  radioOption: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    justifyContent: "flex-start",
    "& input": {
      padding: 0,
      height: "25px",
      width: "25px",
      cursor: "pointer",
      position: "relative",
    },
    "& label": {
      position: "relative",
      cursor: "pointer",
    },
    "& input::before": {
      content: "''",
      backgroundColor: "#fff",
      border: "1.5px solid #0291AE",
      borderRadius: "50%",
      height: "25px",
      width: "25px",
      background: "#fff",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
      cursor: "pointer",
      marginRight: "5px",
    },
    "& input:checked::after": {
      top: "4px",
      left: "4px",
      width: "17px",
      borderRadius: "50%",
      background: " #0291AE",
      height: "17px",
      content: "''",
      position: "absolute",
    },
    "& input:checked::before": {},
  },
  chatButtons: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  chatButton: {
    borderRadius: "50%",
    border: "2px solid #EEEEEE",
    height: "56px",
    width: "56px",
    borderRadius: "50%",
    padding: "5px",
    transition: "0.3s ease",
    "&.active": {
      border: "2px solid #0291AE",
    },
  },
  chatIcon: {
    background: "linear-gradient(180deg, #02AE7F 0%, #02AE2A 100%)",
    height: "42px",
    width: "42px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  colorPickerContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    visibility: "hidden",
    opacity: "0",
    zIndex: "1",
    transition: "0.3s ease",
    "&.active": {
      visibility: "visible",
      opacity: "1",
    },
  },
  colorContainer: {
    height: "56px",
    width: "56px",
    padding: "5px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
  },
  togglerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },
  toggler: {
    width: "45px",
    height: "25px",
    borderRadius: "28px",
    background: "#D9D9D9",
    overflow: "hidden",
    position: "relative",
    padding: "4px",
    transition: "0.3s ease",
    cursor: "pointer",
    "&.active": {
      background: "#02AE7F",
      "& $toggle": {
        left: "55%",
      },
    },
  },
  toggle: {
    height: "100%",
    width: "17px",
    background: "#fff",
    borderRadius: "100%",
    position: "relative",
    left: "0",
    transition: "0.3s ease",
  },
}));

const ChatButtonGenerator = () => {
  const classes = useStyles();
  const [activeAccordian, setActiveAccordian] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [byDefaultOpen, setByDefaultOpen] = useState(false);
  const [toggleBrandColor, setToggleBrandColor] = useState(false);
  const [brandColor, setBrandColor] = useState("#02AE7F");
  const [toggleButtonColor, setToggleButtonColor] = useState(false);
  const [welcomeMsg, setWelcomeMsg] = useState(
    "Hello! How can we assist you today?"
  );
  const [chatWidgetIcons, setChatWidgetIcons] = useState([
    { iconName: "chatWidgetIcon1", colorCode: "#25D366", iconSrc: wpChatIcon },
    { iconName: "chatWidgetIcon2", colorCode: "#08B57B", iconSrc: wpChatIcon },
    { iconName: "chatWidgetIcon3", colorCode: "#02AE7F", iconSrc: wpChatIcon },
    { iconName: "chatWidgetIcon4", colorCode: "#D9D9D9", iconSrc: wpChatIcon },
    { iconName: "chatWidgetIcon5", colorCode: "#FF3D00", iconSrc: wpChatIcon },
  ]);

  const [selectedIcon, setSelectedIcon] = useState(chatWidgetIcons[4]);
  const [buttonColor, setButtonColor] = useState(selectedIcon.colorCode);
  useEffect(() => {
    setSelectedIcon((prev) => ({ ...prev, colorCode: buttonColor }));
    setChatWidgetIcons((prev) =>
      prev.map((icon) =>
        icon.iconName === selectedIcon.iconName
          ? { ...icon, colorCode: buttonColor }
          : icon
      )
    );
  }, [buttonColor, selectedIcon.iconName]);

  const partners = [
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
  ];
  const featureList = [
    {
      icon: instantChatIcon1,
      background: instantChatBg1,
      heading: "Why WhatsApp chat widgets?",
      text: "They allow your website visitors to connect with you effortlessly through a WhatsApp chat button and converse with you in real-time.",
    },
    {
      icon: instantChatIcon2,
      background: instantChatBg2,
      heading: "How do live chat widget work?",
      text: "Embed the widget on your website. With just one click, your customers can start a conversation with you from any page, reducing friction.",
    },
    {
      icon: instantChatIcon3,
      background: instantChatBg3,
      heading: "Why to use free WhatsApp button generator?",
      text: "Generate a WhatsApp live chat widget in seconds by entering your number, adding a welcome message, and customising the look.",
    },
  ];
  const growingList = [
    {
      icon: growingIcon1,
      background: "#F9F1FF",
      heading: "All-in-one platform",
      text: "Manage leads, track deals, automate workflows, and communicate across multiple channels from a single platform.",
    },
    {
      icon: growingIcon2,
      background: "#F4FAFF",
      heading: "AI automation",
      text: "Automate chat flows and follow-ups, and leverage AI-driven insights to boost sales and marketing performance.",
    },
    {
      icon: growingIcon4,
      background: "#FFF5F1",
      heading: "Analytics and reporting",
      text: "Gain real-time insights into sales, leads, activities, conversations, campaigns, with visual dashboards.",
    },
    {
      icon: growingIcon3,
      background: "#F9F1FF",
      heading: "Customisation and integrations",
      text: "Customise pipelines, stages, and more. Integrate with multiple tools like WhatsApp, Instagram, etc.",
    },
    {
      icon: growingIcon5,
      background: "#FFF5E8",
      heading: "Security and support",
      text: "Ensure data privacy with encryption, access controls, and compliance standards. Experience 24/7 customer support.",
    },
  ];
  const experienceList = [
    {
      title: "Education and coaching",
      alt: "WhatsApp link generator for education and coaching",
      desc: "Connect with students seamlessly. Place WhatsApp links on course pages, admission forms, and digital ads to boost enrollments and engagement.",
    },
    {
      title: "Retail and e-commerce",
      alt: "WhatsApp link generator for retail and eCommerce",
      desc: "Convert browsers into buyers instantly. Add WhatsApp chat links to product pages, abandoned cart reminders, and customer support sections for faster sales.",
    },
    {
      title: "Real estate",
      alt: "WhatsApp link generator for real estate",
      desc: "Speed up property inquiries by adding chat links to listings, digital brochures, and social media ads. Allow buyers to schedule viewings with a single click.",
    },
    {
      title: "Travel and hospitality",
      alt: "WhatsApp link generator for travel and hospitality",
      desc: "Provide instant travel assistance by adding WhatsApp links to booking confirmations, tour packages, and hotel websites for real-time customer support.",
    },
  ];

  const faqs = [
    {
      question: "How to use WhatsApp links for business promotion?",
      answer: (
        <>
          Pepper Cloud's free WhatsApp link generator helps you promote your
          business by enabling instant customer connections. You can create and
          share WhatsApp chat links across your website, social media, emails,
          and ads to encourage direct conversations. Use pre-filled messages to
          quickly initiate conversations. By integrating WhatsApp links into
          your marketing strategy, you can generate leads, provide instant
          support, and increase conversions effortlessly.
        </>
      ),
    },
    {
      question: "How to generate a WhatsApp chat link?",
      answer: (
        <>
          Follow the below steps to create your WhatsApp chat link:
          <ul>
            <li>
              Enter your name, business WhatsApp number, email ID, and company
              name.
            </li>
            <li>
              Add an optional pre-filled message for your customers to initiate
              conversation with.
            </li>
            <li>
              Click "Generate link" to create a WhatsApp chat link for your
              business.
            </li>
            <li>
              Share your chat link across channels like websites, social media,
              etc.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "How can I create a WhatsApp link for my business?",
      answer:
        "Use Pepper Cloud free WhatsApp link generator. Simply enter your phone number, email ID, add a custom message (optional), and generate a unique chat link.",
    },
    {
      question: "Can I customise the WhatsApp link?",
      answer:
        "While the link itself cannot be customised, you can use URL shorteners or embed the link in custom buttons on your website.",
    },
    {
      question: "Can multiple people use the same WhatsApp link?",
      answer:
        "Yes! Your WhatsApp link can be shared freely—there's no limit to the number of users or channels.",
    },
    {
      question: "Can I change the pre-filled message after creating the link?",
      answer:
        "Once a link is generated, the pre-filled message cannot be changed. However, you can easily create a new link with an updated message.",
    },
    {
      question:
        "Can I schedule messages to be sent via the generated WhatsApp link?",
      answer: (
        <>
          The link generator itself doesn't offer scheduling features, but you
          can{" "}
          <a
            href="https://blog.peppercloud.com/how-to-send-whatsapp-broadcast-messages-a-complete-guide/"
            target="_blank"
          >
            schedule a broadcast message
          </a>{" "}
          from WhatsApp CRM that may contain your link.
        </>
      ),
    },
    {
      question:
        "How to add WhatsApp links on Instagram, Facebook, and Twitter?",
      answer: (
        <>
          <ul>
            <li>
              Create your link using Pepper Cloud's free WhatsApp link
              generator. Copy the chat link.
            </li>
            <li>Go to your Instagram, Facebook, or Twitter profile.</li>
            <li>Click on the edit profile button.</li>
            <li>
              Paste your WhatsApp link in the appropriate field, such as the
              website field.
            </li>
            <li>Save the changes.</li>
          </ul>
          Alternatively, you can also share the link along with the posts or
          tweets.
        </>
      ),
    },
    {
      question: "How secure are the WhatsApp links generated by Pepper Cloud?",
      answer:
        "Our WhatsApp links are as secure as WhatsApp's end-to-end encryption. We don't store or have access to any chat content.",
    },
    {
      question: "Which is the best app to create a WhatsApp link?",
      answer:
        "Pepper Cloud's free WhatsApp link generator is very convenient and easy to use",
    },
    {
      question: "What are the benefits of using a WhatsApp link generator?",
      answer:
        "Using a WhatsApp link generator can provide several benefits for businesses, including making it easier for customers to contact them on WhatsApp, increasing engagement and conversions, and providing a more seamless customer experience",
    },
    {
      question: "How to use WhatsApp links to boost your customer engagement?",
      answer: (
        <>
          You can easily connect with customers by sharing WhatsApp chat links
          across messaging channels like WhatsApp, Facebook, Instagram,
          Telegram, WeChat. You can also email your link to your contacts via
          Gmail and Outlook. Pepper Cloud is integrated with all these channels.
          Meet your customers wherever they prefer, simplifying communication
          and boosting engagement.
          <ul>
            <li>Gmail</li>
            <li>Outlook</li>
            <li>WhatsApp</li>
            <li>Facebook</li>
            <li>Instagram</li>
            <li>Telegram</li>
            <li>WeChat</li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I create a WhatsApp chat widget?",
      answer: (
        <>
          Yes, you can create a WhatsApp live chat widget for your website using
          our{" "}
          <a
            href="https://peppercloud.com/whatsapp-crm-features/free-whatsapp–click-to-live-chat-button-widget-generator/"
            target="_blank"
          >
            WhatsApp chat widget generator
          </a>
          . You can also{" "}
          <a
            href="https://peppercloud.com/whatsapp-crm-features/free-whatsapp-qr-code-generator/"
            target="_blank"
          >
            generate WhatsApp QR codes
          </a>{" "}
          for seamless offline-to-online engagement.
        </>
      ),
    },
  ];

  const { ref, inView } = useInView({
    threshold: 0.5, // adjust as needed
    triggerOnce: false,
  });

  useEffect(() => {
    let timer;
    if (inView) {
      timer = setInterval(() => {
        setActiveAccordian((prev) => (prev + 1) % experienceList.length);
      }, 5500);
    }
    // Clear the timer when the component unmounts or when inView changes.
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [inView, experienceList.length]);

  const scrollToSection = () => {
    document.getElementById("formSection").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <main>
      <SEO
        title={
          "Free WhatsApp Link Generator | Create Click-to-Chat Links Instantly"
        }
        pathname={
          "https://peppercloud.com//whatsapp-crm-feature/free-whatsapp-link-generator/"
        }
        keywords="whatsapp link generator,generate whatsapp message link,create whatsapp link to chat"
        description={
          " Generate a free WhatsApp click-to-chat link in seconds! Use Pepper Cloud’s WhatsApp Link Generator to create shareable WhatsApp links for business, marketing, and customer support."
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
      />
      <section className={classes.whatsappSection}>
        <div className="container">
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <motion.h1
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-18"
                style={{ color: "#97D2FF" }}
              >
                Free WhatsApp chat button & widget generator
              </motion.h1>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 font-white"
              >
                Boost conversations with free WhatsApp chat widget
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18"
                style={{ color: "#A2BED3" }}
              >
                Create a WhatsApp chat button and add it to your landing pages,
                product pages, contact pages, blog posts, and more. Be where
                your customers are and make customer interactions seamless.
              </motion.p>
              <div className={classes.listContainer}>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Let website visitors connect with you in one click
                  </p>
                </div>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Boost conversions with real-time chat support
                  </p>
                </div>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Customise the widget to match your brand identity
                  </p>
                </div>
              </div>
              <div className="buttonContaienr">
                {/* <Link
                  to={
                    "/whatsapp-crm?utm_source=Homepage&utm_medium=CTA&utm_content=Explore+WhatApp+CRM"
                  }
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="transparentButton font-wix-medium font-16"
                >
                  Explore WhatApp CRM
                </Link> */}
                <button
                  onClick={() => scrollToSection()}
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className=" primaryButton font-wix-medium font-18 font-white"
                >
                  Generate WhatsApp chat widget code
                </button>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <LazyImage
                source={heroSectionImage}
                width={"100%"}
                alt={"Free WhatsApp link generator"}
              />
            </motion.div>
          </div>
          <div className={classes.centerContainer}>
            <div className={classes.rewards}>
              <img
                src={whatsappBusinessPartner}
                alt="WhatsApp Business Partner"
              />
              <div className="border" />
              <img src={metaBusinessPartner} alt="Meta Business Partner" />
            </div>
          </div>
        </div>
      </section>
      <section className={clsx(classes.benefitSection)}>
        <div className="container">
          <div className={classes.integrationHeader}>
            <div className={classes.integrationRight}>
              <LazyImage
                source={quickGuideImage}
                alt={"What is WhatsApp link generator?"}
                width={"100%"}
                className={classes.integrationRightImage}
              />
            </div>
            <div className={classes.integrationLeft}>
              <motion.div
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="sectionTag"
              >
                <p className="font-18  font-wix-semibold color-black2">
                  Quick guide
                </p>
              </motion.div>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-black1"
              >
                What is a WhatsApp chat button generator?
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black2 mt20"
              >
                WhatsApp chat button generator is a tool that enables businesses
                to create a custom chat button for website. It can be customised
                as per the needs. Pepper Cloud WhatsApp chat button generator is
                a free, no-code tool that allows you to create a floating
                WhatsApp button for your website and enables users to start a
                chat with your business instantly.
              </motion.p>
              <div className={classes.pills}>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    100% Free
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Customisable widget
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Instant setup
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    No coding required
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.moreFeatures}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 color-black1"
            >
              Say goodbye to long forms, hello to instant chats!
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Make communication seamless with Pepper Cloud free WhatsApp chat
              button generator. Let your visitors connect with you instantly,
              improving engagement and conversions.
            </motion.p>
          </div>
          <div className="body">
            {featureList.map((feature) => (
              <div
                className={classes.featureContainer}
                style={{ backgroundImage: `url(${feature.background})` }}
              >
                <LazyImage
                  source={feature.icon}
                  alt={feature.heading}
                  className={classes.featureIcon}
                  // alt={listItem.alt}
                />
                <p className="font-wix-semibold font-24 font-white">
                  {feature.heading}
                </p>
                <p className="font-wix-mediu font-18 font-white">
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="formSection" className={classes.supportSystem}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Create a <img alt="WhatsApp" src={whatsAppFeatureIcon} /> WhatsApp
              live chat button in seconds
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 font-white"
            >
              Enhance customer engagement by adding a WhatsApp chat widget to
              your website. Let visitors start a conversation instantly with a
              single click—no coding required.
            </motion.p>
          </div>
          <div className={classes.formBoxContainerBox}>
            <div className={classes.formContainer}>
              <div className={classes.formHeader}>
                <p className="font-28 font-wix-semibold font-black1">
                  Follow these simple steps to generate a WhatsApp chat button
                  for your website:
                </p>
              </div>
              <div className={classes.formBody}>
                <motion.div
                  // whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  // transition={{ duration: 1, delay: 0.3 }}
                  className={classes.stepBox}
                >
                  <div
                    className={classes.stepBoxHeader}
                    onClick={() => setActiveStep(0 === activeStep ? -1 : 0)}
                  >
                    <h3 className="font-20 font-wix-semibold">
                      Button appearance
                    </h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        0 === activeStep && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.stepBoxBody}
                    in={activeStep === 0}
                  >
                    <p className="font-16 font-wix-regular color-black1">
                      Choose from multiple attractive button styles to match
                      your brand identity.
                    </p>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Button text{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 25 chars)
                        </span>
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        maxLength={25}
                      />
                    </div>
                    <div className={classes.flexOptions}>
                      <div className={classes.optionInputContainer}>
                        <label className="font-16 font-wix-medium color-black1">
                          Brand colour
                        </label>
                        <div className={classes.colorInput}>
                          <div
                            className={clsx(
                              classes.colorPickerContainer,
                              toggleBrandColor && "active"
                            )}
                          >
                            <HexColorPicker
                              color={brandColor}
                              onChange={setBrandColor} // Corrected to directly set the new color
                              onBlur={() => setToggleBrandColor(false)}
                            />
                          </div>
                          <div className={classes.colorContainer}>
                            <div
                              className={classes.color}
                              style={{ background: brandColor }}
                              onClick={() => setToggleBrandColor(true)}
                            />
                          </div>
                          <p className="font-18 font-wix-medium color-black1">
                            {brandColor}
                          </p>
                        </div>
                      </div>
                      <div className={classes.optionInputContainer}>
                        <label className="font-16 font-wix-medium color-black1">
                          Chat button colour
                        </label>
                        <div className={classes.colorInput}>
                          <div
                            className={clsx(
                              classes.colorPickerContainer,
                              toggleButtonColor && "active"
                            )}
                          >
                            <HexColorPicker
                              color={buttonColor}
                              onChange={setButtonColor}
                              onBlur={() => setToggleButtonColor(false)}
                            />
                          </div>
                          <div className={classes.colorContainer}>
                            <div
                              className={classes.color}
                              style={{ background: buttonColor }}
                              onClick={() => setToggleButtonColor(true)}
                            />
                          </div>
                          <p className="font-18 font-wix-medium color-black1">
                            {buttonColor}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Position{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Bottom)
                        </span>
                      </label>
                      <div className={classes.colorInput}>
                        <div className={classes.radioGroup}>
                          <div className={classes.radioOption}>
                            <input
                              type="radio"
                              name="position"
                              value="left"
                              id="left"
                              // checked={activePosition === 'left'}
                              // onChange={() => setActivePosition('left')}
                            />
                            <label
                              for="left"
                              className="font-18 font-wix-medium color-black1"
                            >
                              Left
                            </label>
                          </div>
                          <div className={classes.radioOption}>
                            <input
                              id="right"
                              type="radio"
                              name="position"
                              value="right"
                              // checked={activePosition === 'right'}
                              // onChange={() => setActivePosition('right')}
                            />
                            <label
                              for="right"
                              className="font-18 font-wix-medium color-black1"
                            >
                              Right
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Chat button icon
                      </label>
                      <div className={classes.chatButtons}>
                        {chatWidgetIcons.map((chatWidgetIcon, index) => (
                          <div
                            className={clsx(
                              classes.chatButton,
                              selectedIcon.iconName ===
                                chatWidgetIcon.iconName && "active"
                            )}
                            id={chatWidgetIcon.iconName}
                            onClick={() => {
                              setSelectedIcon(chatWidgetIcons[index]);
                              setButtonColor(chatWidgetIcon.colorCode);
                            }}
                          >
                            <div
                              className={classes.chatIcon}
                              style={{ background: chatWidgetIcon.colorCode }}
                            >
                              <img src={chatWidgetIcon.iconSrc} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                </motion.div>
                <motion.div
                  // whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  // transition={{ duration: 1, delay: 0.3 }}
                  className={classes.stepBox}
                >
                  <div
                    className={classes.stepBoxHeader}
                    onClick={() => setActiveStep(1 === activeStep ? -1 : 1)}
                  >
                    <h3 className="font-20 font-wix-semibold">
                      Chat widget settings
                    </h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        1 === activeStep && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.stepBoxBody}
                    in={activeStep === 1}
                  >
                    <p className="font-16 font-wix-regular color-black1">
                      Edit the widget with your details for a personalized
                      experience
                    </p>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Name
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Business email ID
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Brand name
                      </label>
                      <input
                        placeholder="Enter your company name"
                        className={classes.optionInput}
                        type="text"
                      />
                    </div>

                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        WhatsApp number{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Enter WhatsApp number with country code without '+')
                        </span>
                      </label>
                      <input
                        className={classes.optionInput}
                        type="number"
                        placeholder="Enter your WhatsApp number"
                      />
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Brand image URL
                      </label>
                      <input
                        className={classes.optionInput}
                        type="url"
                        placeholder="Add URL"
                      />
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Welcome message{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 40 chars)
                        </span>
                      </label>
                      <textarea
                        className={classes.optionInput}
                        maxLength={40}
                        rows={2}
                        type="text"
                        value={welcomeMsg}
                        onChange={() => setWelcomeMsg(Value)}
                      />
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Pre-filled message - Optional{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 40 chars)
                        </span>
                      </label>
                      <textarea
                        className={classes.optionInput}
                        maxLength={40}
                        rows={2}
                        type="text"
                        value={welcomeMsg}
                        onChange={() => setWelcomeMsg(Value)}
                      />
                    </div>
                    <div className={classes.togglerContainer}>
                      <p className="font-16 font-wix-medium color-black1">
                        Open chat widget by default
                      </p>
                      <div
                        className={clsx(
                          classes.toggler,
                          byDefaultOpen && "active"
                        )}
                        onClick={() => setByDefaultOpen(!byDefaultOpen)}
                      >
                        <div className={classes.toggle} />
                      </div>
                    </div>
                  </Collapse>
                </motion.div>
              </div>
            </div>
            <div className={classes.qrContainer}>
              <div className={classes.qrContainerInner}>
                <p className="font-wix-semibold font-20 font-black1">
                  Your WhatsApp chat button preview
                </p>
                <div className="chatWidgetContainer">
                  <div className="chatWidgetBox">
                    <div
                      className="chatWidgetHeaderContainer"
                      style={{ background: brandColor }}
                    >
                      <img
                        className="companyIconChatWidget"
                        src={
                          "https://blog.peppercloud.com/content/images/size/w256h256/2023/05/image--5--1.png"
                        }
                      />
                      <p className="widgetCompanyName">Pepper Cloud</p>
                    </div>
                    <div className="chatWidgetBodyContainer">
                      <div className="message">
                        Hello, have a question about your service. Can you
                        please help me?
                      </div>
                    </div>
                    <div className="chatWidgetFooterContainer">
                      <button
                        className="widgetSendButton"
                        style={{
                          background: brandColor,
                          borderColor: brandColor,
                        }}
                      >
                        Chat with us
                      </button>
                    </div>
                  </div>
                  <div
                    className="widgetButton"
                    style={{ background: selectedIcon.colorCode }}
                  >
                    <img
                      src={selectedIcon.iconSrc}
                      className="widgetButtonIcon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className={classes.supportBox}
            // style={{ background: point.background }}
          >
            <div className={classes.broadcastLeftContainer}>
              <p className="font-40 font-wix-regular font-white">
                Benefits of using a WhatsApp chat button generator
              </p>
              <div className={classes.pointers}>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Reduce lead drop-offs and connect with potential clients
                    instantly with the free website chat widget.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Improve customer engagement and satisfaction by enabling
                    customers to connect with you in just a click.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Auto-send welcome message and assist customers to initiate
                    chat with a pre-filled conversation starter.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Boost conversions by enabling potential customers to connect
                    with your business through live chat.
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.broadcastRightContainer}>
              <LazyImage
                source={benifitImage}
                alt={"Benefits of WhatsApp link generator"}
                className={classes.broadcastImg}
              />
            </div>
          </motion.div>
        </div>
      </section>
      <section className={classes.experienceContainer} ref={ref}>
        <div className="container">
          <div className={classes.experienceContainerHeader}>
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 text-black1"
            >
              Turn website visits into real conversations
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Enhance customer service, boost lead generation, and build
              meaningful connections across industries with WhatsApp chat
              buttons. Explore the application and use cases of live chat
              widgets.
            </motion.p>
          </div>
          <div className={classes.experienceInnerContainer}>
            <div className={classes.experienceContainerLeft}>
              {experienceList.map((faq, index) => (
                <motion.div
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 1, delay: index * 0.01 }}
                  className={clsx(
                    classes.faqBox,
                    index === activeAccordian && "active"
                  )}
                  onClick={() => setActiveAccordian(index)}
                >
                  <div className={classes.faqHeader}>
                    <h3 className="font-20 font-wix-semibold">{faq.title}</h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        index === activeAccordian && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.body}
                    in={activeAccordian === index}
                  >
                    <p className="font-18 font-wix-regular color-black2">
                      {faq.desc}
                    </p>
                    {/* <motion.img
                      initial={{ opacity: 1, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      src={experienceImg1}
                      alt={faq.alt}
                    /> */}
                  </Collapse>
                </motion.div>
              ))}
            </div>
            <div className={classes.experienceContainerRight}>
              <motion.img
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                src={experienceImg1}
                alt={"WhatsApp link generator for retail and eCommerce"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.growingBusiness}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Why Pepper Cloud CRM is a game-changer for your business
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 font-white"
            >
              Pepper Cloud CRM makes it easy to create your free WhatsApp chat
              widget and track every lead, conversations, and follow-up—all in
              one place.
            </motion.p>
            <Link
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact"
            >
              Book a free demo
            </Link>
          </div>
        </div>
        <Swiper
          slidesPerView={3.3}
          // centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          loop={false}
          speed={5000}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          // freeMode={true}
          className={classes.broadcastContainerInner}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              slidesPerView: 1.3,
              slidesPerGroup: 1,
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 10,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: 3.3,
              centeredSlides: false,
              spaceBetween: 30,
              grabCursor: true,
            },
          }}
        >
          {growingList.map((feature) => (
            <SwiperSlide
              className={classes.featureContainer}
              style={{ background: feature.background }}
            >
              <LazyImage
                source={feature.icon}
                alt={feature.heading}
                className={classes.featureIcon}
                // alt={listItem.alt}
              />
              <p className="font-wix-semibold font-24 color-black1">
                {feature.heading}
              </p>
              <p className="font-wix-mediu font-18 color-black1">
                {feature.text}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.absoulteCard}>
          <TruestedSection background={transformBackgroundImage} />
        </div>
      </section>
      <section className={classes.stripSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h3
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-24 font-black1"
            >
              Best CRM with WhatsApp integration - Trusted by SMEs globally
            </motion.h3>
          </div>
        </div>
        <Swiper
          className={classes.partnerSlider}
          slidesPerView="auto"
          spaceBetween={60}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 20,
            },
            // For larger screens (desktop)
            768: {
              freeMode: false,
              autoplay: {
                delay: 100,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              speed: 2000,
              loop: true,
              allowTouchMove: true,
            },
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide className={classes.partnerSlide}>
              <img src={partner.icon} alt={partner.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <div className={classes.testimonials}>
        <TestimonialSlider
          noButton={true}
          heading={"Real stories. Real results."}
          desc={
            "Discover how businesses are transforming customer engagement with Pepper Cloud."
          }
        />
      </div>
      <div className={classes.wpFaq}>
        <FaqSection
          backgroundColor={"#F1FFF9"}
          heading={"FAQ - WhatsApp chat button/widget generator"}
          faqs={faqs}
        />
      </div>

      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              From website visits to real conversations—instantly!
            </motion.h2>
            <button
              className="primaryButton font-20 font-wix-medium font-white"
              onClick={() => scrollToSection()}
            >
              Generate WhatsApp Link
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChatButtonGenerator;
